<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <label>Selecione uma equipe para conectar ao canal</label>
          <select
            class="form-control"
            v-model="changed_team_id"
            @change="change_team"
          >
            <option
              v-for="team in teams"
              :key="team.id"
              :value="team.id_cr_samu"
            >
              {{ team.name }}
            </option>
          </select>
        </div>
        <div class="col-md-12 mt-2 centered">
          <div class="alert alert-danger" v-if="connected == 0" role="alert">
            Não conectado ao WebSocket
          </div>
          <div class="alert alert-warning" v-if="connected == 1" role="alert">
            Conectando ...
          </div>
          <div class="alert alert-success" v-if="connected == 2" role="alert">
            Conectado com sucesso ao canal
          </div>
        </div>
        <div class="col-md-12 mt-2" v-if="connected == 2">
          <label>Última resposta recebida via WebSocket</label>
          <json-viewer
            :value="JSON.parse(received.message)"
            :expand-depth="15"
            copyable
            boxed
            sort
          ></json-viewer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      team_id: 1,
      changed_team_id: 1,
      teams: [],
      connected: 0,
      received: {
        message: 0,
      },
    };
  },
  components: {},
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "teams";

      axios
        .get(api)
        .then((response) => {
          self.teams = response.data.data;

          if (self.teams.length > 0) {
            self.team_id = self.teams[0].id_cr_samu;
            self.changed_team_id = self.teams[0].id_cr_samu;
            self.getWebSocket();
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    change_team() {
      const self = this;
      Echo.leave("nova-ocorrencia." + self.team_id);
      self.connected = 0;
      self.received = {
        message: 0,
      };
      setTimeout(() => {
        self.team_id = self.changed_team_id;
        self.getWebSocket();
      }, 100);
    },
    getWebSocket() {
      const self = this;
      setTimeout(() => {
        self.connected = 1;
      }, 1000);
      setTimeout(() => {
        Echo.private("nova-ocorrencia." + self.team_id).listen(
          ".NovaOcorrencia",
          (data) => {
            var data = JSON.parse(data.message);
            console.log(data);
            self.received = data;
          }
        );
        self.connected = 2;
      }, 2000);
    },
  },
  destroyed() {},
  mounted() {
    const self = this;

    self.getDashboard();

    // let intervl = 0;

    // setTimeout(function () {
    //   while (intervl < 1000) {
    //     clearInterval(intervl);
    //     console.log("limpando intervals");
    //     intervl++;
    //   }
    // }, 1000);

    // if (self.interval == "") {
    //   self.interval = setInterval(function () {
    //     self.getDashboard();
    //   }, 10000);
    // }

    // Echo.private("user.1").listen(".Alert", (data) => {
    //   console.log(data);
    // });
  },
  created() {},
};
</script>

<style></style>
